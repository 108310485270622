var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.IS_TENCENT_ENV
    ? _c("Tencent")
    : _c(
        "div",
        { staticClass: "home", style: { height: _vm.mainHeight } },
        [
          _c(
            "div",
            { staticClass: "home__logo" },
            [
              _vm.$slots.home_logo
                ? [_vm._t("home_logo")]
                : [
                    _vm.logoImg
                      ? _c("DecodeImage", {
                          staticClass: "home__logo__img",
                          attrs: { path: _vm.logoImg },
                        })
                      : _vm._e(),
                  ],
            ],
            2
          ),
          _vm.backgroundImg
            ? _c("DecodeImage", {
                staticClass: "home__background__img",
                attrs: {
                  path: _vm.backgroundImg,
                  "background-image-mode": true,
                },
              })
            : _vm._e(),
          _c(
            "van-swipe",
            {
              ref: "swipe_ref",
              staticClass: "home__swipe",
              attrs: { "show-indicators": false, loop: true, autoplay: 3000 },
              scopedSlots: _vm._u([
                {
                  key: "indicator",
                  fn: function () {
                    return [
                      _c("img", {
                        staticClass:
                          "home__swipe__button home__swipe__button--left",
                        attrs: {
                          src: require("@/assets/images/swipe_left.png"),
                          alt: "",
                        },
                        on: { click: _vm.onPrevClick },
                      }),
                      _c("img", {
                        staticClass:
                          "home__swipe__button home__swipe__button--right",
                        attrs: {
                          src: require("@/assets/images/swipe_right.png"),
                          alt: "",
                        },
                        on: { click: _vm.onNextClick },
                      }),
                    ]
                  },
                  proxy: true,
                },
              ]),
            },
            _vm._l(_vm.swipeImgList, function (item, index) {
              return _c("van-swipe-item", { key: index }, [
                _c(
                  "div",
                  { staticClass: "home__swipe__item" },
                  [
                    _c(
                      "div",
                      { staticClass: "home__swipe__item__img" },
                      [_c("DecodeImage", { attrs: { path: item.bg } })],
                      1
                    ),
                    _vm.foregroundImg
                      ? _c("DecodeImage", {
                          staticClass: "home__foreground-img",
                          attrs: { path: _vm.foregroundImg },
                        })
                      : _vm._e(),
                    item.title
                      ? _c(
                          "div",
                          { staticClass: "home__swipe__item__title" },
                          [_c("DecodeImage", { attrs: { path: item.title } })],
                          1
                        )
                      : _vm._e(),
                  ],
                  1
                ),
              ])
            }),
            1
          ),
          _c(
            "div",
            { staticClass: "bottom_btns" },
            [
              _vm.IS_PC
                ? _c("PCPart", {
                    staticClass: "home__bottom home__bottom--pc",
                    attrs: {
                      "button-style": _vm.buttonStylePc,
                      "button-before-style": _vm.buttonBeforeStyle,
                      "button-after-style": _vm.buttonAfterStyle,
                      "button-background-img": _vm.buttonBackgroundImg,
                    },
                  })
                : _vm._e(),
              _vm.IS_iOS
                ? _c("IOSPart", {
                    staticClass: "home__bottom home__bottom--ios",
                    attrs: {
                      "app-code": _vm.appCode,
                      "api-hosts": _vm.apiHosts,
                      "show-download-icon": true,
                      "button-style": _vm.buttonStyleIos,
                      "download-recommend-tag-img": _vm.downloadRecommendTagImg,
                      "logo-icon": _vm.logoIcon,
                      "ios-tips": _vm.iosTips,
                      "button-before-style": _vm.buttonBeforeStyle,
                      "button-after-style": _vm.buttonAfterStyle,
                      "button-img": _vm.buttonImg,
                      "button-background-img": _vm.buttonBackgroundImg,
                      iosButtonImg: _vm.iosButtonImg,
                    },
                    on: { rendered: _vm.onRendered },
                  })
                : _vm._e(),
              _vm.IS_ANDROID
                ? _c("AndroidPart", {
                    ref: "ref_android",
                    staticClass: "home__bottom home__bottom--android",
                    attrs: {
                      "button-style": _vm.buttonStyleAndroid,
                      "app-code": _vm.appCode,
                      "show-download-icon": true,
                      "button-before-style": _vm.buttonBeforeStyle,
                      "button-after-style": _vm.buttonAfterStyle,
                      "button-background-img": _vm.buttonBackgroundImg,
                      androidButtonImg: _vm.androidButtonImg,
                    },
                  })
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }