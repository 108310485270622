<template>
  <Tencent v-if="IS_TENCENT_ENV" />

  <div
    v-else
    class="home"
    :style="{ height: mainHeight }"
  >
    <div class="home__logo">
      <template v-if="$slots.home_logo">
        <slot name="home_logo" />
      </template>
      <template v-else>
        <DecodeImage
          v-if="logoImg"
          :path="logoImg"
          class="home__logo__img"
        />
      </template>
    </div>

    <DecodeImage
      v-if="backgroundImg"
      :path="backgroundImg"
      class="home__background__img"
      :background-image-mode="true"
    />

    <van-swipe
      ref="swipe_ref"
      class="home__swipe"
      :show-indicators="false"
      :loop="true"
      :autoplay="3000"
    >
      <template #indicator>
        <img
          class="home__swipe__button home__swipe__button--left"
          src="@/assets/images/swipe_left.png"
          alt=""
          @click="onPrevClick"
        />
        <img
          class="home__swipe__button home__swipe__button--right"
          src="@/assets/images/swipe_right.png"
          alt=""
          @click="onNextClick"
        />
      </template>

      <van-swipe-item
        v-for="(item, index) in swipeImgList"
        :key="index"
      >
        <div class="home__swipe__item">
          <div class="home__swipe__item__img">
            <DecodeImage :path="item.bg" />
          </div>
          <DecodeImage v-if="foregroundImg" :path="foregroundImg" class="home__foreground-img" />
          <div v-if="item.title" class="home__swipe__item__title">
            <DecodeImage :path="item.title" />
          </div>

        </div>
      </van-swipe-item>
    </van-swipe>

    <div class="bottom_btns">
      <PCPart
        class="home__bottom home__bottom--pc"
        v-if="IS_PC"
        :button-style="buttonStylePc"
        :button-before-style="buttonBeforeStyle"
        :button-after-style="buttonAfterStyle"
        :button-background-img="buttonBackgroundImg"
      />
      <IOSPart
        class="home__bottom home__bottom--ios"
        v-if="IS_iOS"
        :app-code="appCode"
        :api-hosts="apiHosts"
        :show-download-icon="true"
        @rendered="onRendered"
        :button-style="buttonStyleIos"
        :download-recommend-tag-img="downloadRecommendTagImg"
        :logo-icon="logoIcon"
        :ios-tips="iosTips"
        :button-before-style="buttonBeforeStyle"
        :button-after-style="buttonAfterStyle"
        :button-img="buttonImg"
        :button-background-img="buttonBackgroundImg"
        :iosButtonImg="iosButtonImg"
      />
      <AndroidPart
        ref="ref_android"
        class="home__bottom home__bottom--android"
        :button-style="buttonStyleAndroid"
        v-if="IS_ANDROID"
        :app-code="appCode"
        :show-download-icon="true"
        :button-before-style="buttonBeforeStyle"
        :button-after-style="buttonAfterStyle"
        :button-background-img="buttonBackgroundImg"
        :androidButtonImg="androidButtonImg"
      />
    </div>
  </div>
</template>

<script>
import DecodeImage from '@/components/common/DecodeImage.vue'

import PCPart from './PCPart.vue'
import IOSPart from './IOSPart.vue'
import AndroidPart from './AndroidPart.vue'
import Tencent from './Tencent.vue'

import { decryptImage } from '@/../utils'
import { IS_PC, IS_ANDROID, IS_iOS, IOS_H5 } from '@/../config/index'
import { IS_TENCENT_ENV } from '@/../config'

const percent = 0.15

export default {
  components: {
    Tencent,
    AndroidPart,
    IOSPart,
    PCPart,
    DecodeImage
  },
  props: {
    backgroundImg: {
      type: String,
      default: require('@/assets/images/common_bg.png')
    },
    logoImg: {
      type: String,
      default: ''
    },
    logoIcon: {
      type: String,
      default: ''
    },
    foregroundImg: {
      type: String,
      default: ''
    },
    swipeImgList: {
      type: Array,
      default: () => []
    },
    appCode: {
      type: String,
      default: ''
    },
    apiHosts: {
      type: Array,
      default: () => []
    },
    buttonStyleIos: {
      type: Object,
      default: () => ({})
    },
    buttonStyleAndroid: {
      type: Object,
      default: () => ({})
    },
    buttonStylePc: {
      type: Object,
      default: () => ({})
    },
    buttonBeforeStyle: {
      type: Object,
      default: () => ({})
    },
    buttonAfterStyle: {
      type: Object,
      default: () => ({})
    },
    buttonImg: {
      type: Object,
      default: () => ({})
    },
    downloadRecommendTagImg: {
      type: String,
      default: ''
    },
    iosTips: {
      type: String
    },
    buttonBackgroundImg: {
      type: String,
      default: ''
    },
    iosButtonImg: {
      type: String,
      default: ''
    },
    androidButtonImg: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      IS_PC,
      IS_ANDROID,
      IS_iOS,
      mainHeight: '',
      IS_TENCENT_ENV
    }
  },
  created() {
    this.mainHeight = window.innerHeight + 'px'
    this.$nextTick(() => {
      if (this.IS_ANDROID) {
        this.onRendered()
      }
    })
  },
  methods: {
    onPrevClick() {
      this.$refs.swipe_ref.prev()
    },
    onNextClick() {
      this.$refs.swipe_ref.next()
    },
    onRendered(btns) {
      const dom = document.querySelector('.bottom_btns')
      this.touchAreaHeight = window.innerHeight - dom.clientHeight
      this.buttons = btns
    },
    onTouchStart(e) {
      const touch = e.touches[0]
      const clientX = touch.clientX
      const clientY = touch.clientY
      const w_width = window.innerWidth
      const h = this.touchAreaHeight
      if (
        (clientX > 0 && clientX < w_width * percent && clientY < h) ||
        (clientX > w_width * (1 - percent) &&
          clientX < w_width &&
          clientY < h) ||
        (clientY > 0 && clientY < h * percent)
      ) {
        this.toPage()
      }
    },
    toPage() {
      if (this.IS_iOS) {
        let url = ''
        this.buttons.some((btn) => {
          if (btn.btn_type === IOS_H5) {
            return (url = btn.download_url || btn.link)
          }
        })
        url ? (window.location.href = url) : ''
      }
      else if (this.IS_ANDROID) {
        this.$refs.ref_android.download()
      }
    }
  }
}
</script>

<style scoped>
@import url('../../../assets/css/homepage.css');

.home {
  height: 100vh;
  width: 100%;
  overflow: hidden;
  position: relative;
}

.home__logo {
  position: absolute;
  z-index: 2;
  width: 100%;
  margin-top: 20px;
  display: flex;
  justify-content: center;
  pointer-events: none;
}

.home__logo__img {
  /* 頁面上指定 **/
}

.home__background__img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 0;
  background-repeat: no-repeat;
  background-size: cover;
  pointer-events: none;
}

.home__foreground-img {
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 2;
  width: 100%;
  pointer-events: none;
}

.home__swipe {
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 1;
}

.home__swipe__item {
  position: relative;
  height: 100%;
}

.home__swipe__item__img {
  z-index: 0;
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.home__swipe__item__img img {
  width: 100%;
}

.home__swipe__item__title {
  z-index: 3;
  position: absolute;
  bottom: 15%;
  width: 100%;
  display: flex;
  justify-content: center;
}

.home__swipe__item__title img {
  width: 90%;
}

.home__swipe__button {
  width: 15px;
  height: 24px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 1;
}

.home__swipe__button--left {
  left: 10px;
}

.home__swipe__button--right {
  right: 15px;
}

.home__bottom {
  position: absolute;
  width: 100%;
  z-index: 2;
}

.home__bottom--pc {
  bottom: 50px;
}

.home__bottom--android {
  bottom: 16px;
}

.home__bottom--ios {
  bottom: 5%;
}
</style>
