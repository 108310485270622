import { setUserIdentifier } from '../utils'
import Version from '../config/version'
require('./assets/js/fetch.min')

export function initialize(appCode) {
  setUserIdentifier()
  window.onload = () => {
    document.addEventListener(
      'touchstart',
      (event) => {
        if (event.touches.length > 1) {
          event.preventDefault()
        }
      },
      { passive: false }
    )

    let lastTouchEnd = 0
    document.addEventListener(
      'touchend',
      (event) => {
        const now = new Date().getTime()
        if (now - lastTouchEnd <= 300) {
          event.preventDefault()
        }
        lastTouchEnd = now
      },
      false
    )
  }

  // 在 window 上挂载版本号和传入的 appCode
  window.console.log(`Version: ${Version}`)
  window.console.log(`AppCode: ${appCode}`)
  window.console.log(`AutoDownLoad: ${window.auto_download}`)
}
